
import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { map } from "rxjs/operators";
import { Products, ProductsMockStore } from "src/app/shared/store-mocks/products.store";
import { UniAuthFacade } from "unifonic-spa-common/src";

type State = {
    selectedItems: string[]
}

const initialState: State = {
    selectedItems: [Products.ALL],
}

@Injectable()
export class CreateFormProductStore extends ComponentStore<State>{
    constructor(
        private productsMockStore: ProductsMockStore,
        private authFacade: UniAuthFacade
    ){
        super({...initialState})
    }

    //selectors
    readonly selectedItems$ = this.select((state) => state.selectedItems)



    readonly flowStudioActivity$ = this.authFacade.featureFlags$.pipe(
        map(ff => !ff['2025-q-1-ci-alaris'])
    )
    readonly campaignActivity$ = this.authFacade.featureFlags$.pipe(
        map(ff => !ff['2025-q-1-ci-alaris'])
    )
    readonly apiActivity$ = this.authFacade.featureFlags$.pipe(
        map(ff => !ff['2025-q-1-ci-alaris'])
    )

    disabled$ = this.select(
        this.flowStudioActivity$,
        this.campaignActivity$,
        this.apiActivity$,
        (flowStudioActivity, campaignActivity, apiActivity) => {
            const disabled = [Products.CHATBOT, Products.FLOW_STUDIO, Products.AGENT_CONSOLE]

            if(!flowStudioActivity){
                disabled.push(Products.FLOW_STUDIO)
            }

            if(!campaignActivity){
                disabled.push(Products.CAMPAIGNS)
            }

            if(!apiActivity){
                disabled.push(Products.API)
            }

            return disabled
    })

    

    readonly vm$ = this.select(
        this.productsMockStore.viewEntities$,
        this.selectedItems$,
        this.disabled$,
        (entities, selectedItems,disabled) => {
            return {
                entities,
                selectedItems,
                disabled
            }
        } 
    )

    //updaters
    readonly setSelectedItems = this.updater((state, selectedItems: string[]) => {
        return {...state, selectedItems: [...selectedItems]}
    })

}
