import { Injectable } from "@angular/core";
import { ComponentStore } from '@ngrx/component-store';
import { map } from "rxjs/operators";
import { Channels, ChannelsMockStore } from "src/app/shared/store-mocks/channels.store";
import { UniAuthFacade } from "unifonic-spa-common/src";

type State = {
    selectedItems: string[]
    disabled: string[]
}

const initialState: State = {
    selectedItems: [Channels.ALL],
    disabled: [Channels.WHATSAPP, Channels.VOICE, Channels.PUSH_NOTIFICATION, Channels.EMAIL, Channels.FACEBOOK, Channels.TWITTER]
}

@Injectable()
export class CreateFormChannelsStore extends ComponentStore<State>{
    constructor(
        private channelsMockStore: ChannelsMockStore,
        private authFacade: UniAuthFacade
    ){
        super({...initialState})
    }

    //selectors
    readonly selectedItems$ = this.select((state) => state.selectedItems)


    whatsappActivity$ = this.authFacade.featureFlags$.pipe(
      map(ff => ff['2023-q-2-ci-whatsapp'] && !ff['2025-q-1-ci-alaris'])
    )
    voiceActivity$ = this.authFacade.featureFlags$.pipe(
      map(ff => ff['2024-q-3-ci-voice'] && !ff['2025-q-1-ci-alaris'])
    )


    disabled$ = this.select(
      this.whatsappActivity$,
      (whatsappActivity) => {
        const disabled = [
          Channels.VOICE,
          Channels.PUSH_NOTIFICATION,
          Channels.EMAIL,
          Channels.FACEBOOK,
          Channels.TWITTER
        ]

        if(!whatsappActivity){
          disabled.push(Channels.WHATSAPP)
        }
        return disabled;
      }
    )

    readonly vm$ = this.select(
        this.channelsMockStore.viewEntities$,
        this.selectedItems$,
        this.disabled$,
        (entities, selectedItems,disabled) => {
            return {
                entities,
                selectedItems,
                disabled
            }
        }
    )

    //updaters
    readonly setSelectedItems = this.updater((state, selectedItems: string[]) => {
        return {...state, selectedItems: [...selectedItems]}
    })

}
